import { useEffect } from 'react';
import styled from 'styled-components';
import { Icon, UserAvatar } from '@elfsight-universe/ui-common';
import { customScrollbarMixin } from '@elfsight-universe/ui-common/src/components/custom-scrollbar-mixin';
import CheckBold16 from '@icons/check-bold-16.svg';
import { useSwitchAccountMutation, useUserAccountsQuery } from '@api';
import { ScheduledToastType, useScheduledToast } from '@utils';
import { catchUnhandledError } from '@modules/_error';
import { useCurrentAccountPid } from '@modules/_app/utils/use-current-account-pid';
import { useUser } from '../../_app/app-user-provider';

export interface SwitchAccountControlProps {
  onClose: () => void;
}

export function SwitchAccountControl({ onClose }: SwitchAccountControlProps) {
  const { user } = useUser();
  const { value: currentAccountPid, clear: clearCurrentAccountPid } =
    useCurrentAccountPid();
  const { schedule: scheduleToast } = useScheduledToast({
    type: ScheduledToastType.ACCOUNT_SWITCHED
  });
  const { data } = useUserAccountsQuery();
  const accounts = data?.accounts;
  const { mutate: switchAccount, isSuccess } = useSwitchAccountMutation({
    onError: (error) => {
      catchUnhandledError(error);
    }
  });
  const onSwitchAccount = (accountPid: string) => () => {
    const isCurrentAccountSelected =
      accountPid === currentAccountPid ||
      accountPid === user?.effectiveAccount.pid;

    if (isCurrentAccountSelected) {
      onClose();
      return;
    }

    switchAccount({
      accountPid
    });
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    scheduleToast();
    clearCurrentAccountPid();
    window.location.pathname = '/';
  }, [isSuccess]);

  if (!accounts || !user) {
    return;
  }

  return (
    <List>
      {accounts.map(({ pid, avatar, firstName, lastName, email, isOwned }) => (
        <Item
          key={pid}
          selected={user.effectiveAccount.pid === pid}
          onClick={onSwitchAccount(pid)}
        >
          <Avatar
            src={avatar}
            size={32}
            placeholderArgs={{ email, firstName, lastName }}
          />

          <Info>
            <Caption>
              {!firstName && !lastName ? (
                <>
                  <Email>{email}</Email>{' '}
                </>
              ) : (
                <>
                  {firstName} {lastName}{' '}
                </>
              )}

              {isOwned && (
                <BadgeContainer>
                  <Badge>Owner</Badge>
                </BadgeContainer>
              )}
            </Caption>

            <Checked>
              {user.effectiveAccount.pid === pid && (
                <Icon size={16} fill="black" component={CheckBold16} />
              )}
            </Checked>
          </Info>
        </Item>
      ))}
    </List>
  );
}

const List = styled.div`
  position: relative;
  height: calc(100% - 30px);
  margin-top: 8px;
  overflow-y: auto;

  ${customScrollbarMixin}
`;

const Item = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  margin: 2px 0;
  padding: 8px 12px;
  transition: all 0.2s;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.gray5 : theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 40px);
`;

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Email = styled(Ellipsis)`
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.black};
`;

const BadgeContainer = styled.div`
  position: relative;
`;

const Badge = styled.div`
  display: inline-block;
  padding: 1px 4px;
  font-size: 9px;
  line-height: 11px;
  font-weight: 600;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.gray10};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 32px;
`;

const Avatar = styled(UserAvatar)``;

const Checked = styled.div`
  width: 16px;
  height: 16px;
`;

const Caption = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 24px);
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.black};
`;
