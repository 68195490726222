import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import {
  adFeatureResolver,
  appsCountFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  viewsLimitFeatureResolver,
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver
} from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolvers';
import { aiReviewsTranslationFeatureResolver } from './feature-resolvers/ai-reviews-translation-feature-resolver';
import { advancedCustomizationFeatureResolver } from './feature-resolvers/advanced-customization';
import { aiPostGenerationFeatureResolver } from './feature-resolvers/ai-post-generation-feature-resolver';

export const basicFeatureResolvers: FeatureResolver[] = [
  appsCountFeatureResolver,
  websitesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver
];
