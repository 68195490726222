import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_SHARED_WIDGETS_LIST_PATH,
  GetSharedWidgetsListRequest,
  GetSharedWidgetsListResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSharedWidgetsListQuery(
  dto: GetSharedWidgetsListRequest,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<GetSharedWidgetsListResponse> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetSharedWidgetsListResponse>(
    [GET_SHARED_WIDGETS_LIST_PATH, ...Object.values(dto)],
    () =>
      client
        .get(GET_SHARED_WIDGETS_LIST_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetSharedWidgetsListResponse>(),
    {
      keepPreviousData: true,
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
