import { useState } from 'react';
import { Button, IconButton, Tooltip } from '@elfsight-universe/ui-common';
import { Plus16 } from '@elfsight/icons';
import { useCollaboratorsUsageQuery } from '@api';
import AddUser from '@icons/add-user.svg';
import { InviteCollaboratorModal } from '@modules/collaborators-modals/invite-collaborator-modal';
import { CollaboratorsLimitReachedModal } from '@modules/collaborators-modals/collaborators-limit-reached-modal';
import { useInvalidateCollaboratorsRelatedQueries } from '../utils';

type CollaboratorsInvitationModalContainerProps = {
  placement: 'header' | 'collaboratorsPage';
};

export function CollaboratorsInvitationModalContainer({
  placement
}: CollaboratorsInvitationModalContainerProps) {
  const { data: collaboratorsUsage } = useCollaboratorsUsageQuery();
  const invalidateCollaboratorsRelatedQueries =
    useInvalidateCollaboratorsRelatedQueries();

  const [isInviteCollaboratorModalOpen, setInviteCollaboratorModalOpen] =
    useState(false);
  const [
    isCollaboratorsLimitReachedModalOpen,
    setCollaboratorsLimitReachedModalOpen
  ] = useState(false);

  const onInviteMemberClick = () => {
    if (Number(collaboratorsUsage?.collaboratorsUsage) >= 1) {
      setCollaboratorsLimitReachedModalOpen(true);
      return;
    }
    setInviteCollaboratorModalOpen(true);
  };

  return (
    <>
      {placement === 'header' && (
        <Tooltip content="Invite Members">
          <IconButton icon={AddUser} onClick={onInviteMemberClick} />
        </Tooltip>
      )}

      {placement === 'collaboratorsPage' && (
        <Button
          variation="accentPrimary"
          leftIcon={Plus16}
          onClick={onInviteMemberClick}
        >
          Invite Members
        </Button>
      )}

      <InviteCollaboratorModal
        isOpen={isInviteCollaboratorModalOpen}
        onSuccess={() => invalidateCollaboratorsRelatedQueries()}
        onRequestClose={() => setInviteCollaboratorModalOpen(false)}
        withLinkToMembers={placement === 'header'}
      />

      <CollaboratorsLimitReachedModal
        isOpen={isCollaboratorsLimitReachedModalOpen}
        onRequestClose={() => setCollaboratorsLimitReachedModalOpen(false)}
      />
    </>
  );
}
