import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  getThemeOrCustomColor,
  IconButton,
  ThemeColor
} from '@elfsight-universe/ui-common';
import { Close16 } from '@elfsight/icons';

type ToastProps = {
  icon?: JSX.Element | string | null;
  iconColor?: ThemeColor | string;
  message: ReactNode;
  withClose?: boolean;
  withAction?: boolean;
  disableActionShrink?: boolean;
  onClose?: () => void;
  onAction?: () => void;
  actionText?: ReactNode;
  closeIconColor?: ThemeColor | string;
};

export function Toast({
  icon = null,
  iconColor,
  message,
  withClose,
  withAction,
  disableActionShrink = true,
  onClose,
  onAction,
  actionText,
  closeIconColor = 'rgba(255, 255, 255, 0.5)'
}: ToastProps) {
  const handleAction = () => {
    onAction && onAction();
    onClose && onClose();
  };

  return (
    <Container>
      <IconWrapper $color={iconColor}>{icon}</IconWrapper>

      <Message>{message}</Message>

      {withAction && (
        <ActionButton
          small
          padding={8}
          onClick={handleAction}
          variation="text"
          textColor="accentOnBlack"
          disableShrink={disableActionShrink}
        >
          {actionText}
        </ActionButton>
      )}

      {withClose && (
        <CloseButton
          padding={4}
          rounded
          small
          icon={Close16}
          customColor={closeIconColor}
          onClick={onClose}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ $color?: ThemeColor | string }>`
  display: flex;
  align-items: center;

  ${({ theme, $color }) =>
    $color &&
    css`
      color: ${getThemeOrCustomColor($color, theme)};
    `};

  svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const Message = styled.div`
  word-break: break-word;
`;

const CloseButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: -4px;
  margin-left: 6px;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ActionButton = styled(Button)`
  margin: -6px 0 -6px 6px;
`;
