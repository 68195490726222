import {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react';
import { useRouter } from 'next/router';

const IGNORED_NESTED_PAGES_REGEXP = ['apps/[^/]+/pricing']; // handle nested page url like separate page
interface IHistoryContext {
  history: string[];
  back(fallbackRoute?: string, clear?: boolean): void;
  hasPreviousPage: boolean;
}

const HistoryContext = createContext<IHistoryContext | null>(null);

export function AppHistoryProvider({ children }: PropsWithChildren) {
  const { push, events } = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  const back = (fallbackRoute = '/', clearParams = false) => {
    const previousIndex = history.length - 2;
    const previousHistory = history[previousIndex];

    if (!previousHistory) {
      return push(fallbackRoute);
    }

    setHistory(history.slice(0, previousIndex));

    return push(clearParams ? clearURL(previousHistory) : previousHistory);
  };

  useEffect(() => {
    const handleBeforeHistoryChange = (url: string) => {
      setHistory((previous) => {
        const lastIndex = previous.length - 1;
        const lastURL = previous[lastIndex];

        if (lastURL && isSamePage(lastURL, url, IGNORED_NESTED_PAGES_REGEXP)) {
          previous.splice(lastIndex, 1, url);
          return previous;
        }

        return [...previous, url];
      });
    };

    events.on('beforeHistoryChange', handleBeforeHistoryChange);

    return () => {
      events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      back,
      history,
      hasPreviousPage: history.length > 1
    }),
    [back, history]
  );

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
}

const isSamePage = (
  urlA: string,
  urlB: string,
  excludeRegExp: string[] = []
) => {
  const isSameParentPageRegExp = /^(\/[^\/?]*)?(.*)/;
  const isSameParentPage =
    urlA.replace(isSameParentPageRegExp, '$1') ===
    urlB.replace(isSameParentPageRegExp, '$1');

  for (let i = 0; i < excludeRegExp.length; i++) {
    const excludeRegExpItem = new RegExp(excludeRegExp[i], 'i');

    const isUrlAMatch = excludeRegExpItem.test(urlA);
    const isUrlBMatch = excludeRegExpItem.test(urlB);
    const isSameNestedPage = isSameParentPage && isUrlAMatch && isUrlBMatch;
    const isOneOfUrlNested = isSameParentPage && (isUrlAMatch || isUrlBMatch);

    if (isSameNestedPage) {
      return true;
    }

    if (isOneOfUrlNested) {
      return false;
    }
  }

  return isSameParentPage;
};

const clearURL = (url: string) => url.replace(/^(\/[^?#]*)/, '$1');

export function useHistory() {
  const context = useContext(HistoryContext);

  if (context === null) {
    throw new Error(
      '`useHistory` must be nested inside an `AppHistoryProvider`.'
    );
  }

  return context;
}
