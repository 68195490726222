import { IsUUID } from 'class-validator';

export const DECLINE_PROJECT_INVITATION_PATH =
  'project/decline-project-invitation';

export class DeclineProjectInvitationRequest {
  @IsUUID()
  projectInvitationPid: string;
}

export type DeclineProjectInvitationResponseError =
  'PROJECT_INVITATION_DOES_NOT_EXIST';
