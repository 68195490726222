import { MouseEvent } from 'react';
import { Link } from '@elfsight-universe/ui-common';
import { beacon } from '@modules/beacon';
import { usePageContext } from '@modules/_app';
import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

export function ViewsLimitTooltip({
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'>) {
  const { override } = usePageContext();

  const openBeaconArticle =
    (articleId: string) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      override({ beaconHidden: false });
      beacon.article(articleId);
    };

  const learnMoreLink = (
    <Link underline onClick={openBeaconArticle('6298722a5732000792520c76')}>
      Learn more
    </Link>
  );

  return (
    <FeatureTooltip
      content={
        <>
          The number of views is the number of times a widget loads on a webpage
          or via a share link. Exceeding this monthly limit will temporarily
          deactivate your app. {learnMoreLink}
        </>
      }
      {...forwardingProps}
    >
      {children}
    </FeatureTooltip>
  );
}
