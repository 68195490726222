export const prettifyEmailLineBreak = (email: string) => {
  const splitEmail = email.split('@');
  const splitDomain = splitEmail[1].split('.');
  return (
    <>
      {splitEmail[0]}
      <wbr />@{splitDomain[0]}
      <wbr />.{splitDomain[1]}
    </>
  );
};
