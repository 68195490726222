import { useRouter } from 'next/router';
import { WarningTriangleFilled } from '@elfsight/icons';
import { prettifyEmailLineBreak } from '@elfsight-universe/ui-common';
import { Toast } from '@modules/_app/app-toaster/toast';

type ToastInviteAlreadyExistCollaboratorProps = {
  onClose: () => void;
  email: string;
  withLink?: boolean;
};

export function ToastInviteAlreadyExistCollaborator({
  email,
  withLink,
  onClose
}: ToastInviteAlreadyExistCollaboratorProps) {
  const { push } = useRouter();

  return (
    <Toast
      message={
        <>
          Member with the email {prettifyEmailLineBreak(email)} is already added
          to the Workspace.
        </>
      }
      icon={<WarningTriangleFilled />}
      actionText="See Members"
      withAction={withLink}
      onAction={() => push('/members')}
      onClose={onClose}
    />
  );
}
