import React from 'react';
import { Typography } from '@elfsight-universe/ui-common';
import { AccountMemberRole } from '@elfsight-universe/service-core-contracts/iam/enums/account-member-role.enum';
import { GetCollaboratorsResponseProject } from '@elfsight-universe/service-core-contracts/iam';
import { getPermissionsByRole } from '../utils';
import { CollaboratorsProjects } from '../components/collaborators-projects';

export type CollaboratorsPermissionsProps = {
  role: AccountMemberRole;
  projects?: GetCollaboratorsResponseProject[];
  isInvitation?: boolean;
  isDeactivated?: boolean;
};

export function CollaboratorsPermissions({
  role,
  projects,
  isDeactivated,
  isInvitation
}: CollaboratorsPermissionsProps) {
  return (
    <Typography
      variant="caption"
      color={isDeactivated || isInvitation ? 'gray50' : 'black'}
    >
      {getPermissionsByRole(role)}{' '}
      {projects && (
        <>
          in <CollaboratorsProjects projects={projects} />
        </>
      )}
    </Typography>
  );
}
