import Image from 'next/image';
import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { Link, P } from '@elfsight-universe/ui-common';
import { DefaultContainer } from '@modules/deals/ui';
import { getDealURLForButton } from '@modules/deals/utils';

type SwitchToAnnualDetailTemplateProps = AccountDeal;

export function SwitchToAnnualDetailTemplate(
  accountDeal: SwitchToAnnualDetailTemplateProps
) {
  const { title, textImage, alias } = accountDeal.deal;

  return (
    <DefaultContainer title={title} textImage={textImage} alias={alias}>
      <P big>
        <Link color="accent" href={getDealURLForButton(accountDeal)}>
          Switch your payment period
        </Link>{' '}
        to annual and save 17% right now!
      </P>

      <div>
        <Image
          src={'/assets/deals/switch-to-annual-how-to-apply.png'}
          height={110}
          width={260}
          alt=""
        />
      </div>
    </DefaultContainer>
  );
}
