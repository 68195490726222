import { IsNotEmpty, IsString, IsUUID, MaxLength } from 'class-validator';
import { WidgetError } from '../errors';

export const UPDATE_WIDGET_NAME_PATH = 'widget/update-widget-name';

export class UpdateWidgetNameRequest {
  @IsUUID()
  widgetPid: string;

  @IsString()
  @IsNotEmpty({ message: 'Widget name is required.' })
  @MaxLength(255, { message: 'Must be shorter than 255 characters.' })
  name: string;
}

export type UpdateWidgetNameResponseError = WidgetError.WIDGET_DOES_NOT_EXIST;
