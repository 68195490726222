import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

export function ProjectsSharingTooltip({
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'>) {
  return (
    <FeatureTooltip
      content={
        <>
          The Widget Sharing feature allows you to grant access to the widgets
          of your project to your collaborators or clients.
        </>
      }
      {...forwardingProps}
    >
      {children}
    </FeatureTooltip>
  );
}
