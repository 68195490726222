import { IsUUID } from 'class-validator';

export const RESEND_ACCOUNT_MEMBER_INVITATION_PATH =
  'iam/resend-account-member-invitation';

export class ResendAccountMemberInvitationRequest {
  @IsUUID()
  accountMemberInvitationPid: string;
}

export type ResendAccountMemberInvitationResponseError =
  'ACCOUNT_MEMBER_INVITATION_DOES_NOT_EXIST';
