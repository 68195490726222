import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  RESEND_PROJECT_INVITATION_PATH,
  ResendProjectInvitationRequest,
  ResendProjectInvitationResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useProjectInvitationMutation(
  options: UseMutationOptions<
    Response,
    ResendProjectInvitationResponseError,
    ResendProjectInvitationRequest
  > = {}
) {
  return useMutation<
    Response,
    ResendProjectInvitationResponseError,
    ResendProjectInvitationRequest
  >(
    (data) =>
      client.post(RESEND_PROJECT_INVITATION_PATH, {
        json: data
      }),
    {
      ...options,
      retry: false
    }
  );
}
