import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme, ThemeColor } from '../../theme';
import { getThemeOrCustomColor } from '../../utils';
import { IconContainer } from '../icons/icon-container';

type ButtonStyleProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithTheme<{
    $backgroundColor?: string | ThemeColor;
    $textColor: string | ThemeColor;
    $interactTextColor?: string | ThemeColor;
    $hoverOverlayColor: string;
    $activeOverlayColor?: string;
    $selectedOverlayColor?: string;
    $fullWidth?: boolean;
    $center?: boolean;
    $height: number;
    $active?: boolean;
    $selected?: boolean;
    $padding: number;
    $negativeOffset?: number;
    $fontSize?: number;
    $fontWeight?: number;
    $borderRadius?: number;
    $disableShrink?: boolean;
    $iconHoverColor?: string | ThemeColor;
  }>;

const sizeMixin = ({
  $height,
  $fullWidth,
  $padding,
  $negativeOffset = 0
}: ButtonStyleProps) => {
  const fullWidthWithOffset = $negativeOffset
    ? `calc(100% + ${-$negativeOffset * 2}px)`
    : '100%';

  return css`
    max-width: ${fullWidthWithOffset};
    min-width: ${$height}px;
    height: ${$height}px;
    width: ${$fullWidth ? fullWidthWithOffset : 'fit-content'};
    padding: 0;
    margin: 0 ${$negativeOffset}px;

    ${ButtonContent} {
      padding: 0 ${$padding}px;
    }
  `;
};

const colorMixin = ({
  $textColor,
  $interactTextColor,
  $backgroundColor = 'transparent', // @TODO check does it need transparent by default
  $hoverOverlayColor,
  $activeOverlayColor,
  $selectedOverlayColor,
  $iconHoverColor,
  $active,
  $selected,
  disabled,
  theme
}: ButtonStyleProps) =>
  disabled
    ? css`
        background: ${
          $backgroundColor && $backgroundColor !== 'transparent'
            ? theme.colors.gray5
            : 'transparent'
        };
        color: ${theme.colors.gray10};
      `
    : css`
        color: ${getThemeOrCustomColor($textColor, theme)};
        background: ${getThemeOrCustomColor($backgroundColor, theme)};

        ${ButtonContent} {
          background: ${
            $active && $activeOverlayColor
              ? getThemeOrCustomColor($activeOverlayColor, theme)
              : 'transparent'
          };

          ${() =>
            $selected &&
            $selectedOverlayColor &&
            css`
              background: ${getThemeOrCustomColor($selectedOverlayColor, theme)};
            `}
        }

        :hover {
          color: ${getThemeOrCustomColor(
            $interactTextColor || $textColor,
            theme
          )};
          ${ButtonContent} {
            background: ${getThemeOrCustomColor(
              $active && $activeOverlayColor
                ? $activeOverlayColor
                : $hoverOverlayColor,
              theme
            )};
          }

          ${
            $iconHoverColor &&
            css`
            ${IconContainer} {
              fill: ${getThemeOrCustomColor($iconHoverColor, theme)};
            }
          `
          }
        }
        :active {
          color: ${getThemeOrCustomColor(
            $interactTextColor || $textColor,
            theme
          )};
          ${ButtonContent} {
            background: ${
              $activeOverlayColor
                ? getThemeOrCustomColor($activeOverlayColor, theme)
                : 'transparent'
            };
          }
        }
      `;

export const ButtonSlot = styled.div<{
  _left?: boolean;
  _right?: boolean;
  _outbound?: number;
}>`
  display: flex;
  align-items: center;
  ${({ _left, _outbound = 4 }) =>
    _left &&
    css`
      margin-left: -${_outbound}px;
      margin-right: 8px;
    `}
  ${({ _right, _outbound = 4 }) =>
    _right &&
    css`
      margin-right: -${_outbound}px;
      margin-left: 16px;
    `}
`;

export const ButtonContent = styled.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  :hover,
  :active {
    transition: all 0.2s;
  }
`;

export const Inner = styled.span<{ _noEllipsis?: boolean }>`
  display: inline-block;

  ${({ _noEllipsis }) =>
    !_noEllipsis &&
    css`
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    `}
`;

export const ButtonContainer = styled.button<ButtonStyleProps>`
  display: block;
  position: relative;
  outline: none;
  border: none;
  flex-shrink: ${({ $disableShrink }) => ($disableShrink ? '0' : '1')};
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  font-size: ${({ $fontSize = 14 }) => $fontSize}px;
  line-height: 1.32;
  font-weight: ${({ $fontWeight = 500 }) => $fontWeight};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  border-radius: ${({ $borderRadius = 4 }) => `${$borderRadius}px`};
  text-decoration: none;
  user-select: none;
  overflow: hidden;

  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    -webkit-appearance: none;
  }

  ${sizeMixin}
  ${colorMixin}
`;
