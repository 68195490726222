import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useLastUsedInstalledAppQuery,
  useSharedWithMeFeatureSummaryQuery
} from '@api';

export enum AppPage {
  APP_ALIAS_PAGE = 'app-alias-page',
  SHARED_WITH_ME_PAGE = 'shared-with-the-page'
}

export const useSelectedAppPage = (): AppPage | null => {
  const { query } = useRouter();
  const queryAppAlias = query.alias as string | undefined;

  const { data: sharedWithMeFeatureSummaryData } =
    useSharedWithMeFeatureSummaryQuery();
  const hasSharedWidget = sharedWithMeFeatureSummaryData?.hasSharedWidget;
  const sharedWidgetLastVisitedAt =
    sharedWithMeFeatureSummaryData?.lastVisitedAt;

  const { data: lastUsedInstalledAppData } = useLastUsedInstalledAppQuery();
  const lastUsedAppAlias = lastUsedInstalledAppData?.app?.alias ?? '';
  const lastUsedAppAliasLastUsedAt =
    lastUsedInstalledAppData?.lastUsedAt ?? null;

  const [lastUsedAppPage, setLastUsedAppPage] = useState<AppPage | null>(null);

  useEffect(() => {
    if (queryAppAlias) {
      setLastUsedAppPage(AppPage.APP_ALIAS_PAGE);
      return;
    }

    if (hasSharedWidget && !lastUsedAppAlias) {
      setLastUsedAppPage(AppPage.SHARED_WITH_ME_PAGE);
      return;
    }

    if (!hasSharedWidget && lastUsedAppAlias) {
      setLastUsedAppPage(AppPage.APP_ALIAS_PAGE);
      return;
    }

    if (
      hasSharedWidget &&
      lastUsedAppAlias &&
      sharedWidgetLastVisitedAt &&
      lastUsedAppAliasLastUsedAt
    ) {
      if (
        new Date(sharedWidgetLastVisitedAt) >
        new Date(lastUsedAppAliasLastUsedAt)
      ) {
        setLastUsedAppPage(AppPage.SHARED_WITH_ME_PAGE);
        return;
      }

      if (
        new Date(sharedWidgetLastVisitedAt) <
        new Date(lastUsedAppAliasLastUsedAt)
      ) {
        setLastUsedAppPage(AppPage.APP_ALIAS_PAGE);
        return;
      }

      setLastUsedAppPage(null);
    }

    setLastUsedAppPage(null);
  }, [
    hasSharedWidget,
    lastUsedAppAlias,
    sharedWidgetLastVisitedAt,
    lastUsedAppAliasLastUsedAt
  ]);

  return lastUsedAppPage;
};
