import { useCountPublicAppsQuery, useSingleAppPlansQuery } from '@api';
import { Plans, PlansProps } from './plans';
import { useUpgradeContext } from '../upgrade-context';
import { formatPlans } from '../utils';

export function PlansSingle(
  forwardingProps: Omit<PlansProps, 'isLoading' | 'plans' | 'onPlanSelect'>
) {
  const { data: context } = useUpgradeContext();
  const { data: rawPlans = { payload: [] }, isLoading } =
    useSingleAppPlansQuery({
      appAlias: context?.app?.alias,
      withoutLitePlans: !!context?.hasPaidSubscription
    });
  const { data: countApps } = useCountPublicAppsQuery();

  const plans = formatPlans(rawPlans.payload, {
    app: context?.app,
    effectivePlanPid: context?.effectivePlanPid,
    countPublicApps: countApps?.count
  });

  return (
    <Plans
      isLoading={isLoading}
      plans={plans}
      appName={context?.app?.name}
      {...forwardingProps}
    />
  );
}
