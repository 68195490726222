import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Max,
  Min
} from 'class-validator';
import { SortWidgetsVariant, WidgetStatus, WidgetVisibility } from './enums';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';

export const GET_SHARED_WIDGETS_LIST_PATH = 'widget/get-shared-widgets-list';

export class GetSharedWidgetsListRequest extends PaginatedRequest {
  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  page = 1;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  @Max(50)
  itemsPerPage = 10;

  @IsString()
  @IsOptional()
  search?: string;

  @IsOptional()
  @IsEnum(SortWidgetsVariant)
  sortWidgetsVariant?: SortWidgetsVariant;
}

@Exclude()
export class GetSharedWidgetsListResponse {
  @Expose()
  @Type(() => PaginationResponse)
  meta: PaginationResponse;

  @Expose()
  @Type(() => GetSharedWidgetsListResponseWidget)
  payload: GetSharedWidgetsListResponseWidget[];
}

@Exclude()
export class GetSharedWidgetsListResponseWidgetThumbnail {
  @Expose()
  url: string;

  @Expose()
  width: number;

  @Expose()
  height: number;

  @Expose()
  staled: boolean;
}

@Exclude()
export class GetSharedWidgetsListResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  canExportData: boolean;

  @Expose()
  icon: string;
}

@Exclude()
export class GetSharedWidgetsListResponseOwnerUserProfile {
  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;
}

@Exclude()
export class GetSharedWidgetsListResponseOwner {
  @Expose()
  email: string;

  @Expose()
  @Type(() => GetSharedWidgetsListResponseOwnerUserProfile)
  userProfile: GetSharedWidgetsListResponseOwnerUserProfile | null;
}

@Exclude()
export class GetSharedWidgetsListResponseWidget {
  @Expose()
  pid: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  name: string;

  @Expose()
  status: WidgetStatus;

  @Expose()
  visibility: WidgetVisibility;

  @Expose()
  @Type(() => GetSharedWidgetsListResponseApp)
  app: GetSharedWidgetsListResponseApp;

  @Expose()
  @Type(() => GetSharedWidgetsListResponseWidgetThumbnail)
  thumbnail?: GetSharedWidgetsListResponseWidgetThumbnail;

  @Expose()
  @Type(() => GetSharedWidgetsListResponseOwner)
  owner: GetSharedWidgetsListResponseOwner;
}
