import { IsUUID } from 'class-validator';

export const RESEND_PROJECT_INVITATION_PATH =
  'project/resend-project-invitation';

export class ResendProjectInvitationRequest {
  @IsUUID()
  projectInvitationPid: string;
}

export type ResendProjectInvitationResponseError =
  'PROJECT_INVITATION_DOES_NOT_EXIST';
