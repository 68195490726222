import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { REPORT_SHARED_WITH_ME_VISIT_PATH } from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useReportSharedWithMeVisitMutation(
  options: UseMutationOptions = {}
) {
  return useMutation(() => client.post(REPORT_SHARED_WITH_ME_VISIT_PATH), {
    ...options
  });
}
