import { IsEmail, IsNotEmpty, IsUUID } from 'class-validator';

export const CREATE_PROJECT_INVITATION_PATH =
  'project/create-project-invitation';

export class CreateProjectInvitationRequest {
  @IsUUID()
  projectPid: string;

  @IsNotEmpty({ message: 'Please enter a valid email' })
  @IsEmail(undefined, { message: 'Please enter a valid email' })
  email: string;
}

export type CreateProjectInvitationResponseError =
  | 'COLLABORATORS_LIMIT_REACHED'
  | 'SHARED_PROJECT_ALREADY_EXIST'
  | 'PROJECT_DOES_NOT_EXIST'
  | 'COLLABORATOR_ALREADY_EXIST'
  | 'PROJECT_INVITATION_ALREADY_EXIST';
