import { IsUUID } from 'class-validator';

export const ACCEPT_PROJECT_INVITATION_PATH =
  'project/accept-project-invitation';

export class AcceptProjectInvitationRequest {
  @IsUUID()
  projectInvitationPid: string;
}

export type AcceptProjectInvitationResponseError =
  | 'PROJECT_INVITATION_DOES_NOT_EXIST'
  | 'ACCOUNT_MEMBER_DEACTIVATED'
  | 'ACCOUNT_MEMBER_INVITATION_DOES_NOT_EXIST';
