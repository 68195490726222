import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UPDATE_WIDGET_NAME_PATH,
  UpdateWidgetNameRequest,
  UpdateWidgetNameResponseError
} from '@elfsight-universe/service-core-contracts/widget/update-widget-name';
import { client } from '../client';

export function useUpdateWidgetNameMutation(
  widget: {
    widgetPid: string;
    name?: string | null;
  },
  options: Pick<
    UseMutationOptions<
      Omit<UpdateWidgetNameRequest, 'widgetPid'> | undefined,
      UpdateWidgetNameResponseError,
      Omit<UpdateWidgetNameRequest, 'widgetPid'>
    >,
    'onError' | 'onSuccess'
  > = {}
) {
  return useMutation<
    Omit<UpdateWidgetNameRequest, 'widgetPid'> | undefined,
    UpdateWidgetNameResponseError,
    Omit<UpdateWidgetNameRequest, 'widgetPid'>
  >(async (data) => {
    if (data.name === widget.name) {
      return undefined;
    }

    await client.post(UPDATE_WIDGET_NAME_PATH, {
      json: { widgetPid: widget.widgetPid, ...data }
    });

    return data;
  }, options);
}
