import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const GET_WIDGET_ADDITIONAL_INFO_PATH =
  'widget/get-widget-additional-info';

export class GetWidgetAdditionalInfoRequest {
  @IsUUID()
  widgetPid: string;
}

@Exclude()
export class GetWidgetAdditionalInfoResponse {
  @Expose()
  @Type(() => GetWidgetAdditionalInfoFreeLinkResponse)
  freeLink: {
    display: boolean;
    displayRemove: boolean;
    anchor?: string;
    url?: string;
    removeURL: string;
  };

  @Expose()
  hasExceededViewsUsage: boolean;

  @Expose()
  hasExceededWidgetsUsage: boolean;

  @Expose()
  viewsCountingPeriodEndDate: Date;

  @Expose()
  widgetsLimit: number;

  @Expose()
  isLiteSubscription: boolean;

  @Expose()
  isLiteSubscriptionConfirmed: boolean;

  @Expose()
  shouldProposeUpgrade: boolean;
}

export class GetWidgetAdditionalInfoFreeLinkResponse {
  @Expose()
  display: boolean;
  @Expose()
  displayRemove: boolean;
  @Expose()
  anchor?: string;
  @Expose()
  url?: string;
  @Expose()
  removeURL: string;
}

export type GetWidgetAdditionalInfoResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
