import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ACCEPT_PROJECT_INVITATION_PATH,
  AcceptProjectInvitationRequest,
  AcceptProjectInvitationResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useAcceptProjectInvitationMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      AcceptProjectInvitationResponseError,
      AcceptProjectInvitationRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    Response,
    AcceptProjectInvitationResponseError,
    AcceptProjectInvitationRequest
  >(
    (data) =>
      client.post(ACCEPT_PROJECT_INVITATION_PATH, {
        json: data
      }),
    {
      ...options,
      retry: false
    }
  );
}
