import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const aiPostGenerationFeatureResolver: FeatureResolver = ({
  isPackPlan,
  isLitePlan,
  isBasicPlan,
  appAlias
}: CreateFeatureOptions) => {
  const shouldDisplay = !isPackPlan && appAlias === 'blog';

  if (!shouldDisplay) {
    return;
  }

  return {
    text: (
      <FeatureTooltip
        content={
          <>
            Create a complete blog post with images, headings, and formatting in
            just 15 seconds.
          </>
        }
      >
        <>AI post generation</>
      </FeatureTooltip>
    ),
    excluded: isLitePlan || isBasicPlan
  };
};
