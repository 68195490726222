import { Exclude, Expose } from 'class-transformer';

export const GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH =
  'project/get-shared-with-me-feature-summary';

@Exclude()
export class GetSharedWithMeFeatureSummaryResponse {
  @Expose()
  available: boolean;

  @Expose()
  lastVisitedAt: Date | null;

  @Expose()
  hasSharedWidget: boolean;
}
