import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const installationServiceFeatureResolver: FeatureResolver = (
  { isLitePlan },
  { pricingTableColorTheme }
) => {
  return {
    excluded: isLitePlan,
    text: (
      <FeatureTooltip
        pricingTableColorTheme={pricingTableColorTheme}
        content={
          <>
            Get free installation assistance from our support team. Our experts
            will help resolve any installation issues, ensuring a successful
            setup.
          </>
        }
      >
        <>Free installation service</>
      </FeatureTooltip>
    )
  };
};
