import { FormEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { Button, Form, MOBILE } from '@elfsight-universe/ui-common';
import { ControlLabel } from '@elfsight-universe/ui-common/src/components/controls/control-layout';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import { useCreateInstallationRequestAppealMutation } from '@api';
import { RequestInstallationFormStep1 } from './request-installation-form-step-1';
import { RequestInstallationFormStep2 } from './request-installation-form-step-2';
import { InstallationFormSteps } from './request-installation-tab';

type StepFiledType =
  keyof typeof CreateInstallationRequestAppealRequest.prototype;

type StepType = {
  component: ReactNode;
  fields: StepFiledType[];
};

type StepsMapType = { [key: string]: StepType };

export type RequestInstallationFormProps = {
  currentStep: InstallationFormSteps;
  setStep: (step: InstallationFormSteps) => void;
};

const stepsMap: StepsMapType = {
  1: {
    component: <RequestInstallationFormStep1 />,
    fields: ['platform', 'where', 'details', 'additionalInfo']
  },
  2: {
    component: <RequestInstallationFormStep2 />,
    fields: ['accessDetails', 'contactEmail']
  }
};

export function RequestInstallationForm({
  currentStep,
  setStep,
  ...forwardingProps
}: RequestInstallationFormProps) {
  const {
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
    trigger,
    clearErrors
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const { mutate } = useCreateInstallationRequestAppealMutation();

  const nextStep = async () => {
    const fields = stepsMap[currentStep].fields;
    const isValidFields = await trigger(fields);

    if (!isValidFields) {
      return;
    }

    const nextStep = currentStep + 1;
    if (nextStep in stepsMap) {
      setStep(nextStep as InstallationFormSteps);
    }
  };

  const prevStep = () => {
    const prevStep = currentStep - 1;
    if (prevStep in stepsMap) {
      setStep(prevStep as InstallationFormSteps);
      clearErrors();
      return;
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSubmit((data) => mutate(data))();
  };

  return (
    <>
      <StyledForm
        onSubmit={onSubmit}
        _offsetBetween={[24, 24]}
        {...forwardingProps}
      >
        {stepsMap[currentStep].component}

        <Footer>
          <Actions>
            {currentStep !== 1 && (
              <Button type="button" variation="secondary" onClick={prevStep}>
                Back
              </Button>
            )}

            {currentStep !== 2 && (
              <Button
                type="button"
                variation="accentPrimary"
                onClick={nextStep}
              >
                Next
              </Button>
            )}

            {currentStep === 2 && (
              <Button
                type="submit"
                variation="accentPrimary"
                disabled={isSubmitSuccessful}
              >
                Submit Request
              </Button>
            )}
          </Actions>

          {Object.keys(errors).length !== 0 && (
            <FooterError>Please fill in required fields</FooterError>
          )}
        </Footer>
      </StyledForm>
    </>
  );
}

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 24px;
  margin-top: auto;

  ${MOBILE} {
    align-items: start;
    flex-direction: column;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 12px;
`;

const FooterError = styled.div`
  ${({ theme }) => theme.font.text};
  color: ${({ theme }) => theme.colors.alert};
`;

const StyledForm = styled(Form)`
  ${ControlLabel} {
    ${({ theme }) => theme.font.title4Semibold};
  }
`;
