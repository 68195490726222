import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { ProjectsSharingTooltip } from './projects-sharing-tooltip';

export const projectsSharingFeatureResolver: FeatureResolver = (
  { collaboratorsLimit, isProPlan },
  { pricingTableColorTheme }
) => ({
  text: (
    <ProjectsSharingTooltip pricingTableColorTheme={pricingTableColorTheme}>
      <span>Widget sharing</span>
    </ProjectsSharingTooltip>
  ),
  excluded: collaboratorsLimit === 0 || isProPlan
});
