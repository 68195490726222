export abstract class Result<E, V> {
  constructor(
    protected readonly failed: boolean,
    protected readonly error: E,
    protected readonly value: V
  ) {}

  isFail(): this is FailResult<E> {
    return this.failed;
  }

  isOK(): this is OKResult<V> {
    return !this.failed;
  }

  static fail<E>(error: E) {
    return new FailResult(error);
  }

  static ok<V>(value: V) {
    return new OKResult(value);
  }

  static match<E>(result: FailResult<E>): E;
  static match<V>(result: OKResult<V>): V;
  static match<E, V, ME>(
    result: EitherResult<E, V>,
    mapError: (error: E) => ME
  ): ME | V;
  static match<E, V, ME, MV>(
    result: EitherResult<E, V>,
    mapError: (error: E) => ME,
    mapValue: (value: V) => MV
  ): ME | MV;
  static match<E, V, ME, MV>(
    result: EitherResult<E, V>,
    mapError?: (error: E) => ME,
    mapValue?: (value: V) => MV
  ) {
    if (result.isFail()) {
      return mapError ? mapError(result.getError()) : mapError;
    }

    const value = result.getValue();
    return mapValue ? mapValue(value) : value;
  }
}

export class FailResult<E> extends Result<E, undefined> {
  constructor(error: E) {
    super(true, error, undefined);
  }

  getError() {
    return this.error;
  }
}

export class OKResult<V> extends Result<undefined, V> {
  constructor(value: V) {
    super(false, undefined, value);
  }

  getValue() {
    return this.value;
  }
}

export type EitherResult<E, V> = FailResult<E> | OKResult<V>;

export type ResultErrorType<R> = R extends FailResult<infer E> ? E : never;

export type ResultValueType<R> = R extends OKResult<infer V> ? V : never;
