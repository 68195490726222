import { IsUUID } from 'class-validator';

export const DELETE_SHARED_PROJECT_PATH = 'project/delete-shared-project';

export class DeleteSharedProjectRequest {
  @IsUUID()
  sharedProjectPid: string;
}

export type DeleteSharedProjectResponseError = 'SHARED_PROJECT_DOES_NOT_EXIST';
