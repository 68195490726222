import { useContext } from 'react';
import styled from 'styled-components';
import { Link, TabsContext } from '@elfsight-universe/ui-common';
import { PlanTab } from '@modules/upgrade';
import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

interface WidgetsTooltipProps {
  widgetsLimit: number;
  isPackPlan?: boolean;
  appName?: string;
  countPublicApps?: number;
}

export function WidgetsLimitFeatureTooltip({
  widgetsLimit,
  isPackPlan,
  appName,
  countPublicApps,
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'> & WidgetsTooltipProps) {
  const tabsContext = useContext(TabsContext);
  const switchToPacks = () => tabsContext?.setActiveTab(PlanTab.PACKS);

  function renderContent() {
    if (isPackPlan) {
      return (
        <>
          Pack subscription allows you to create {widgetsLimit}&nbsp;widgets
          within each app ({widgetsLimit}&nbsp;Google Reviews, {widgetsLimit}
          &nbsp;Instagram Feeds, etc).
        </>
      );
    }

    const widgetOrWidgetsLabel = widgetsLimit > 1 ? 'widgets' : 'widget';

    return (
      <>
        <P>
          Create up to {widgetsLimit} {appName} {widgetOrWidgetsLabel}.
          Consider&nbsp;our{' '}
          <Link underline onClick={switchToPacks}>
            Pack plans
          </Link>{' '}
          to access all {countPublicApps ? countPublicApps : ''} apps.
        </P>
      </>
    );
  }

  return (
    <FeatureTooltip content={renderContent()} {...forwardingProps}>
      {children}
    </FeatureTooltip>
  );
}

const P = styled.p`
  margin: 0;

  &:not(:first-child) {
    margin-top: 1.5em;
  }
`;
