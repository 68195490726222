import { FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const advancedCustomizationFeatureResolver: FeatureResolver = (
  { isLitePlan },
  { pricingTableColorTheme }
) => ({
  text: (
    <FeatureTooltip
      pricingTableColorTheme={pricingTableColorTheme}
      content={
        <>
          With advanced customization privileges, you can rely on our team for
          tailor-made widget adjustments using custom CSS.
        </>
      }
    >
      <>Advanced customizations</>
    </FeatureTooltip>
  ),
  excluded: isLitePlan
});
