import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { AccountMemberRole } from '../iam/enums/account-member-role.enum';

export const GET_PROJECT_COLLABORATORS_PATH =
  'project/get-project-collaborators';

export class GetProjectCollaboratorsRequest {
  @IsUUID()
  projectPid: string;
}

@Exclude()
export class GetProjectCollaboratorsResponse {
  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMember)
  accountMembers: GetProjectCollaboratorsResponseAccountMember[];

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMemberInvitation)
  accountMemberInvitations: GetProjectCollaboratorsResponseAccountMemberInvitation[];

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseSharedProject)
  sharedProjects: GetProjectCollaboratorsResponseSharedProject[];

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseProjectInvitation)
  projectInvitations: GetProjectCollaboratorsResponseProjectInvitation[];
}

@Exclude()
export class GetProjectCollaboratorsResponseAccountMemberUserProfile {
  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;
}

@Exclude()
export class GetProjectCollaboratorsResponseAccountMemberUser {
  @Expose()
  email: string;

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMemberUserProfile)
  userProfile: GetProjectCollaboratorsResponseAccountMemberUserProfile | null;
}

@Exclude()
export class GetProjectCollaboratorsResponseAccountMember {
  @Expose()
  pid: string;

  @Expose()
  role: AccountMemberRole;

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMemberUser)
  user: GetProjectCollaboratorsResponseAccountMemberUser;

  @Expose()
  isYou: boolean;

  @Expose()
  isDeactivated: boolean;

  @Expose()
  profilePictureURL: string | null;
}

@Exclude()
export class GetProjectCollaboratorsResponseAccountMemberInvitation {
  @Expose()
  pid: string;

  @Expose()
  email: string;

  @Expose()
  isDeactivated: boolean;

  @Expose()
  role: AccountMemberRole;
}

@Exclude()
export class GetProjectCollaboratorsResponseSharedProject {
  @Expose()
  pid: string;

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMember)
  accountMember: GetProjectCollaboratorsResponseAccountMember;
}

@Exclude()
export class GetProjectCollaboratorsResponseProjectInvitation {
  @Expose()
  pid: string;

  @Expose()
  @Type(() => GetProjectCollaboratorsResponseAccountMemberInvitation)
  accountMemberInvitation: GetProjectCollaboratorsResponseAccountMemberInvitation;
}

export type GetProjectCollaboratorsError = 'PROJECT_DOES_NOT_EXIST';
