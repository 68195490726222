import { useQueryClient } from '@tanstack/react-query';
import {
  GET_LAST_USED_INSTALLED_APP_PATH,
  GetLastUsedInstalledAppResponse
} from '@elfsight-universe/service-core-contracts/widget';

export function useSetLastUsedInstalledAppQueryData() {
  const queryClient = useQueryClient();

  return (appAlias: string) =>
    queryClient.setQueryData<GetLastUsedInstalledAppResponse>(
      [GET_LAST_USED_INSTALLED_APP_PATH],
      () => {
        return {
          app: { alias: appAlias },
          lastUsedAt: new Date()
        };
      }
    );
}
