import { IsOptional, IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const DUPLICATE_WIDGET_PATH = 'widget/duplicate-widget';

export class DuplicateWidgetRequest {
  @IsUUID()
  widgetPid: string;

  @IsUUID()
  @IsOptional()
  projectPid?: string;
}

export type DuplicateWidgetResponseError =
  | WidgetError.WIDGETS_LIMIT_REACHED
  | WidgetError.APP_DOES_NOT_EXIST
  | WidgetError.WIDGET_DOES_NOT_EXIST;
