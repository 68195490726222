import styled from 'styled-components';
import { Button, ButtonContent, Icon } from '@elfsight-universe/ui-common';
import { GetCollaboratorsResponseProject } from '@elfsight-universe/service-core-contracts/iam';
import { ProjectIcon } from '@components';
import { WarningTriangleFilled } from '@elfsight/icons';

export type CollaboratorsProjectsPopoverProps = {
  projects: GetCollaboratorsResponseProject[];
};

export function CollaboratorsProjectsPopover({
  projects
}: CollaboratorsProjectsPopoverProps) {
  return (
    <Content>
      {projects.map(({ projectThumbnailURL, pid, name, active }) => (
        <StyledMenuButton
          key={pid}
          fontWeight={500}
          disabled={!active}
          negativeOffset={0}
          textColor="black"
          interactTextColor="black"
          href={`projects/${pid}`}
          noEllipsis
          fullWidth
          leftSlot={
            <ProjectIcon
              projectName={name}
              projectActive={active}
              projectThumbnailURL={projectThumbnailURL}
              size="small"
            />
          }
          rightSlot={
            !active && (
              <Icon component={WarningTriangleFilled} size={16} fill="alert" />
            )
          }
        >
          {name}
        </StyledMenuButton>
      ))}
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  box-sizing: border-box;
  max-width: 320px;
`;

const StyledMenuButton = styled(Button)`
  height: auto;

  ${ButtonContent} {
    word-break: break-word;
    padding: 8px 16px;
  }
`;
