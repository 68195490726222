import { EXPORT_DATA_PATH } from '@elfsight-universe/service-core-contracts/widget/export-data';
import { ACCOUNT_PID_SESSION_OPTION } from '@constants';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;

export function createFormResponsesURL(widgetPid: string) {
  const accountPid = global.sessionStorage
    ? global.sessionStorage.getItem(ACCOUNT_PID_SESSION_OPTION)
    : undefined;

  const params = new URLSearchParams({ widgetPid });

  if (accountPid) {
    params.set('asAccount', JSON.parse(accountPid));
  }

  return `${NEXT_PUBLIC_SERVICE_CORE__BASE_URL}/${EXPORT_DATA_PATH}?${params.toString()}`;
}
