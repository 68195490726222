import styled, { css } from 'styled-components';
import {
  TabPanel,
  TabProps,
  P,
  CopiedCode,
  Callout,
  Button
} from '@elfsight-universe/ui-common';
import { generateWidgetLink } from '@elfsight-universe/generate-widget-installation';
import { createUpgradeURL } from '@modules/upgrade';
import { ShareLinkShare } from './share-link-share';
import { WidgetInstallDraftCallout } from '../widget-install-draft-callout';
import { WidgetInstallData } from '../widget-install-data.interface';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;

type ShareLinkTabProps = Omit<TabProps, 'children'> & WidgetInstallData;

export function ShareLinkTab({
  tabID,
  widgetPid,
  isDraft,
  onPublishDraft,
  isPublishingDraft,
  appAlias,
  plan
}: ShareLinkTabProps) {
  const link = generateWidgetLink(
    widgetPid,
    NEXT_PUBLIC_SERVICE_CORE__BASE_URL
  );

  const isDisabled = plan?.features?.disableShareByLink ?? false;

  return (
    <TabPanel tabID={tabID}>
      {isDisabled && (
        <StyledCallout
          backgroundColor="warning"
          button={
            <Button
              variation="successPrimary"
              href={createUpgradeURL({
                app: appAlias,
                redirectBack: true,
                claim: 'upgradePlan'
              })}
            >
              Upgrade to Unlock
            </Button>
          }
        >
          The Share Link feature is only available on paid plans.
        </StyledCallout>
      )}
      {/*
        The inert attribute is widely supported by browser (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert)
        and it seems to be supported by React DOM,
        however the corresponding type declaration is missing (https://github.com/facebook/react/issues/17157)
      */}
      <Guide $disabled={isDisabled} {...(isDisabled ? { inert: '' } : {})}>
        <P gb>
          Here&apos;s a direct link to the widget. Feel free to share it with
          others in messengers, social media or even add it to your emails.
        </P>

        <CopiedCode
          color="#197bff"
          textColor="#1155b2"
          buttonText="Copy Link"
          copiedText="Link copied"
          disabled={isDraft}
        >
          {link}
        </CopiedCode>

        {isDraft && onPublishDraft && (
          <StyledWidgetDraftCallout
            onPublishDraft={onPublishDraft}
            isPublishingDraft={isPublishingDraft}
          >
            This is a draft widget. Only published widgets can be shared by a
            link. You need to publish the widget before you share it.
          </StyledWidgetDraftCallout>
        )}

        <ShareLinkShare link={link} widgetPid={widgetPid} />
      </Guide>
    </TabPanel>
  );
}

const StyledWidgetDraftCallout = styled(WidgetInstallDraftCallout)`
  margin: 12px 0;
`;

const Guide = styled.div<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      margin-top: 12px;
      opacity: 0.2;
    `}
`;

const StyledCallout = styled(Callout)`
  margin-bottom: 12px;
`;
