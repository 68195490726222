import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DELETE_SHARED_PROJECT_PATH,
  DeleteSharedProjectRequest,
  DeleteSharedProjectResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useDeleteSharedProjectMutation(
  options: UseMutationOptions<
    undefined,
    DeleteSharedProjectResponseError,
    DeleteSharedProjectRequest
  > = {}
) {
  return useMutation<
    undefined,
    DeleteSharedProjectResponseError,
    DeleteSharedProjectRequest
  >(
    ({ sharedProjectPid }) =>
      client
        .post(DELETE_SHARED_PROJECT_PATH, {
          json: { sharedProjectPid }
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
