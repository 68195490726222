import {
  FeatureTooltip,
  FeatureTooltipProps
} from '../../../components/feature-tooltip';

export function ProjectsLimitTooltip({
  children,
  ...forwardingProps
}: Omit<FeatureTooltipProps, 'content'>) {
  return (
    <FeatureTooltip
      content={
        <>
          Effortlessly organize your widgets, making management simple and
          efficient.
        </>
      }
      {...forwardingProps}
    >
      {children}
    </FeatureTooltip>
  );
}
