import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import type { JsonObject } from '../json.type';
import { WidgetError } from '../errors';

export const GET_WIDGET_PATH = 'widget/get-widget';

export class GetWidgetRequest {
  @IsUUID()
  widgetPid: string;
}

@Exclude()
export class GetWidgetResponsePublishedWidgetRevision {
  @Expose()
  pid: string;

  @Expose()
  settingsHash: string;

  @Expose()
  publishedAt: string;

  @Expose()
  settings: JsonObject;

  @Expose()
  widgetToken: string;
}

@Exclude()
export class GetWidgetResponseUnpublishedWidgetRevision {
  @Expose()
  pid: string;

  @Expose()
  settingsHash: string;

  @Expose()
  updatedAt: string;

  @Expose()
  settings: JsonObject;

  @Expose()
  widgetToken: string;
}

@Exclude()
export class GetWidgetResponseAppRelease {
  @Expose()
  pid: string;

  @Expose()
  configuratorURL: string;

  @Expose()
  version: string;

  @Expose()
  type: string;
}

export class GetWidgetResponseConfiguratorTemplateSelectorConfig {
  @Expose()
  displayNewTemplateSelector: boolean;

  @Expose()
  allTemplatesCategoryPid: string;

  @Expose()
  templatesEndpointURL: string;

  @Expose()
  templateCategoriesEndpointURL: string;
}

@Exclude()
export class GetWidgetResponse {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  meta: JsonObject;

  @Expose()
  isIncipient: boolean;

  @Expose()
  isDraft: boolean;

  @Expose()
  isOwner: boolean;

  @Expose()
  @Type(() => GetWidgetResponseApp)
  app: {
    pid: string;
    name: string;
    alias: string;
    icon: string;
    canExportData: boolean;
    wishlistLink: string | null;
    category: {
      color: string;
    };
  };

  @Expose()
  @Type(() => GetWidgetResponsePublishedWidgetRevision)
  publishedWidgetRevision: GetWidgetResponsePublishedWidgetRevision;

  @Expose()
  @Type(() => GetWidgetResponseUnpublishedWidgetRevision)
  unpublishedWidgetRevision?: GetWidgetResponseUnpublishedWidgetRevision;

  @Expose()
  @Type(() => GetWidgetResponseAppRelease)
  appRelease: GetWidgetResponseAppRelease;

  @Expose()
  @Type(() => GetWidgetResponseConfiguratorTemplateSelectorConfig)
  configuratorTemplateSelectorConfig: GetWidgetResponseConfiguratorTemplateSelectorConfig;

  @Expose()
  updatedAt: string;

  @Expose()
  requestFeatureLink: string;
}

export type GetWidgetResponseError = WidgetError.WIDGET_DOES_NOT_EXIST;

@Exclude()
export class GetWidgetResponseApp {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  canExportData: boolean;

  @Expose()
  wishlistLink: string | null;

  @Expose()
  @Type(() => GetWidgetResponseAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetWidgetResponseAppCategory {
  @Expose()
  color: string;
}
