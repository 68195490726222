import styled, { css } from 'styled-components';
import {
  FontType,
  getColorWithAlpha,
  theme,
  Typography
} from '@elfsight-universe/ui-common';

export type ProjectIconSize = 'small' | 'medium' | 'large';

export interface ProjectIconProps {
  projectName: string;
  projectThumbnailURL?: string;
  projectActive: boolean;
  size?: ProjectIconSize;
}

export function ProjectIcon({
  projectThumbnailURL,
  projectName,
  projectActive,
  size = 'medium'
}: ProjectIconProps) {
  const finalIconSize = {
    small: 14,
    medium: 18,
    large: 20
  }[size];

  const finalCharVariant = {
    small: 'captionSmallUppercase',
    medium: 'title4Semibold',
    large: 'title3Semibold'
  }[size] as FontType;

  const finalSideSize = {
    small: 24,
    medium: 30,
    large: 36
  }[size];

  return (
    <ProjectThumbnail
      _active={projectActive}
      _hasProjectThumbnail={!!projectThumbnailURL}
      _sideSize={finalSideSize}
    >
      {projectThumbnailURL ? (
        <ProjectThumbnailImage
          src={projectThumbnailURL}
          alt={projectName}
          width={finalIconSize}
          height={finalIconSize}
        />
      ) : (
        <ProjectThumbnailChar variant={finalCharVariant} color="accent">
          {projectName[0]}
        </ProjectThumbnailChar>
      )}
    </ProjectThumbnail>
  );
}

const ProjectThumbnail = styled.div<{
  _sideSize: number;
  _hasProjectThumbnail: boolean;
  _active: boolean;
}>`
  height: ${({ _sideSize }) => `${_sideSize}px`};
  min-width: ${({ _sideSize }) => `${_sideSize}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, _hasProjectThumbnail }) =>
      _hasProjectThumbnail
        ? theme.colors.gray10
        : theme.colors.accentTransparent};

  ${({ _hasProjectThumbnail }) =>
    !_hasProjectThumbnail &&
    css`
      background: linear-gradient(
        180deg,
        ${getColorWithAlpha(theme.colors.accent, 0.15)} 0%,
        ${getColorWithAlpha(theme.colors.accent, 0.09)} 100%
      );
    `}

  ${({ _active }) =>
    !_active &&
    css`
      opacity: 0.3;
      mix-blend-mode: luminosity;
    `}
`;

const ProjectThumbnailChar = styled(Typography)`
  text-transform: uppercase;
`;

const ProjectThumbnailImage = styled.img``;
