export function getCollaboratorFinalName({
  firstName,
  lastName,
  email
}: {
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}) {
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || email;
}
