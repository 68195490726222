import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

export const aiReviewsTranslationFeatureResolver: FeatureResolver = ({
  isPackPlan,
  isLitePlan,
  isBasicPlan,
  appAlias
}: CreateFeatureOptions) => {
  const shouldDisplay =
    !isPackPlan && appAlias && appAlias.endsWith('-reviews');

  if (!shouldDisplay) {
    return;
  }

  return {
    text: (
      <FeatureTooltip
        content={
          <>
            Automatically translate customer reviews into the default language
            set in your widget settings, ensuring consistent display across your
            site.
          </>
        }
      >
        <>AI review translation</>
      </FeatureTooltip>
    ),
    excluded: isLitePlan || isBasicPlan
  };
};
