import { useQueryClient } from '@tanstack/react-query';
import {
  GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH,
  GetSharedWithMeFeatureSummaryResponse
} from '@elfsight-universe/service-core-contracts/project';

export function useSetSharedWithMeFeatureSummaryData() {
  const queryClient = useQueryClient();

  return () =>
    queryClient.setQueryData<GetSharedWithMeFeatureSummaryResponse>(
      [GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH],
      (data) => {
        if (!data) {
          return data;
        }

        return {
          ...data,
          lastVisitedAt: new Date()
        };
      }
    );
}
