import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { ProjectsLimitTooltip } from './projects-limit-tooltip';

export const projectsLimitFeatureResolver: FeatureResolver = (
  { projectsLimit },
  { pricingTableColorTheme }
) => ({
  text: (
    <ProjectsLimitTooltip pricingTableColorTheme={pricingTableColorTheme}>
      <span>
        {projectsLimit > 0 ? `${projectsLimit} projects` : 'Projects'}
      </span>
    </ProjectsLimitTooltip>
  ),
  excluded: projectsLimit === 0
});
