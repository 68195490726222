import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { client } from '../client';
import { useUser } from '@modules/_app';
import {
  GET_WIDGET_ADDITIONAL_INFO_PATH,
  GetWidgetAdditionalInfoResponse,
  GetWidgetAdditionalInfoResponseError
} from '@elfsight-universe/service-core-contracts/widget/get-widget-additional-info';

export function useWidgetAdditionalInfoQuery(
  pid: string | undefined,
  {
    enabled,
    ...options
  }: UseQueryOptions<
    GetWidgetAdditionalInfoResponse,
    GetWidgetAdditionalInfoResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<
    GetWidgetAdditionalInfoResponse,
    GetWidgetAdditionalInfoResponseError
  >(
    [GET_WIDGET_ADDITIONAL_INFO_PATH, pid],
    () =>
      client
        .get(GET_WIDGET_ADDITIONAL_INFO_PATH, {
          searchParams: { widgetPid: pid as string }
        })
        .json(),
    {
      enabled:
        (enabled === undefined || enabled) && Boolean(pid) && isAuthenticated,
      ...options
    }
  );
}
