import { createContext, useContext } from 'react';
import { GetSubscriptionAppInfoResponse } from '@elfsight-universe/service-core-contracts/billing';
import { GetInstalledAppsWithViewsIndicatorsResponse } from '@elfsight-universe/service-core-contracts/widget/get-installed-apps-with-views-indicators';
import { DashboardModal } from '@modules/dashboard/dashboard-provider';

export type IDashboardContext = {
  installedApps: GetInstalledAppsWithViewsIndicatorsResponse;
  currentAppInfo: GetSubscriptionAppInfoResponse | undefined;
  isCurrentAppInfoLoading: boolean;
  isCurrentAppInstalled: boolean;
  showSharedWithMeButton: boolean;
  isSharedWithMePage: boolean;
  openModal: (modal: DashboardModal) => Promise<void>;
  closeModal: () => Promise<void>;
};

export const DashboardContext = createContext<IDashboardContext | null>(null);

export function useDashboardContext() {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error(
      '`useDashboardContext` must be nested inside an `DashboardProvider`.'
    );
  }

  return context;
}
