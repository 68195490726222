import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import {
  useCommitUsageInstalledAppMutation,
  useInstalledAppsWithViewsIndicatorsQuery,
  useLastUsedInstalledAppQuery,
  useReportSharedWithMeVisitMutation,
  useSharedWithMeFeatureSummaryQuery
} from '@api';
import { useEvents } from '@modules/_event-bus';
import {
  AppPage,
  useSelectedAppPage,
  useSetLastUsedInstalledAppQueryData,
  useSetSharedWithMeFeatureSummaryData
} from './utils';

type BackToAppsPageProps = {
  appAlias?: string;
  historyReplace?: boolean;
  fallbackRoute?: string;
  query?: ParsedUrlQueryInput;
};

interface ICurrentAppAliasContext {
  currentAppAlias: string;
  setCurrentAppAlias: (appAlias: string) => void;
  isCurrentAppAliasFetched: boolean;
  isReady: boolean;
  myAppsNavLink: string;
  backToAppsPage: (props?: BackToAppsPageProps) => void;
  updateVisitTimeSharedWithMePage: () => void;
  hasSharedWidgetPage: boolean;
  sharedWidgetsAvailable: boolean;
}

export const AppCurrentAppAliasContext =
  createContext<ICurrentAppAliasContext | null>(null);

export function AppCurrentAppAliasProvider({ children }: PropsWithChildren) {
  const { query, replace, push } = useRouter();
  const lastUsedAppPage = useSelectedAppPage();

  const {
    data: lastUsedInstalledAppData,
    isFetched: lastUsedInstalledAppIsFetched
  } = useLastUsedInstalledAppQuery();
  const currentAppAlias = lastUsedInstalledAppData?.app?.alias ?? '';

  const {
    data: sharedWithMeFeatureSummaryData,
    isFetched: sharedWithMeFeatureSummaryIsFetched
  } = useSharedWithMeFeatureSummaryQuery();
  const hasSharedWidget = sharedWithMeFeatureSummaryData?.hasSharedWidget;
  const sharedWidgetsAvailable = sharedWithMeFeatureSummaryData?.available;

  const setLastUsedInstalledAppQueryData =
    useSetLastUsedInstalledAppQueryData();

  const setSharedWithMeFeatureSummaryData =
    useSetSharedWithMeFeatureSummaryData();

  useEvents(['LastUsedInstalledAppUpdated'], ({ payload }) => {
    setLastUsedInstalledAppQueryData(payload.alias as string);
  });

  const { mutate: commitLastUsageApp } = useCommitUsageInstalledAppMutation({
    onMutate: ({ appAlias }) => {
      setLastUsedInstalledAppQueryData(appAlias);
    }
  });
  const { mutate: reportSharedWithMeVisit } =
    useReportSharedWithMeVisitMutation({
      onMutate: () => {
        setSharedWithMeFeatureSummaryData();
      }
    });
  const { data: installedApps = { payload: [] } } =
    useInstalledAppsWithViewsIndicatorsQuery();

  const queryAppAlias = query.alias as string | undefined;
  const finalCurrentAppAlias = queryAppAlias || currentAppAlias;

  const [myAppsNavLink, setMyAppsNavLink] = useState('');

  const setCurrentAppAlias = (alias: string) => {
    commitLastUsageApp({ appAlias: alias });
  };

  const updateVisitTimeSharedWithMePage = () => {
    if (hasSharedWidget && sharedWidgetsAvailable) {
      reportSharedWithMeVisit();
    }
  };

  useEffect(() => {
    if (lastUsedAppPage === AppPage.SHARED_WITH_ME_PAGE) {
      setMyAppsNavLink('/apps/shared-with-me');
      return;
    }

    if (lastUsedAppPage === AppPage.APP_ALIAS_PAGE) {
      const link =
        installedApps.payload.length === 0
          ? '/apps'
          : `/apps/${finalCurrentAppAlias}`;
      setMyAppsNavLink(link);
      return;
    }

    setMyAppsNavLink('/apps');
  }, [lastUsedAppPage, finalCurrentAppAlias, installedApps]);

  const backToAppsPage = ({
    appAlias = myAppsNavLink,
    historyReplace,
    fallbackRoute = '/',
    query = {}
  }: BackToAppsPageProps = {}) => {
    if (!appAlias) {
      return replace(fallbackRoute);
    }

    const filteredQuery = omitBy(query, isNil);
    const method = historyReplace ? replace : push;
    return method({
      pathname: appAlias,
      query: filteredQuery
    });
  };

  const isCurrentAppAliasReady =
    lastUsedInstalledAppIsFetched && sharedWithMeFeatureSummaryIsFetched;

  return (
    <AppCurrentAppAliasContext.Provider
      value={{
        currentAppAlias: finalCurrentAppAlias,
        setCurrentAppAlias,
        isCurrentAppAliasFetched: lastUsedInstalledAppIsFetched,
        isReady: isCurrentAppAliasReady,
        myAppsNavLink,
        backToAppsPage,
        updateVisitTimeSharedWithMePage,
        hasSharedWidgetPage: !!hasSharedWidget,
        sharedWidgetsAvailable: !!sharedWidgetsAvailable
      }}
    >
      {children}
    </AppCurrentAppAliasContext.Provider>
  );
}

export function useAppCurrentAppAliasContext() {
  const context = useContext(AppCurrentAppAliasContext);

  if (context === null) {
    throw new Error(
      '`useAppCurrentAppAliasContext` must be nested inside an `AppCurrentAppAliasProvider`.'
    );
  }

  return context;
}
