import { useQuery } from '@tanstack/react-query';
import { useUser } from '@modules/_app';
import {
  GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH,
  GetSharedWithMeFeatureSummaryResponse
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useSharedWithMeFeatureSummaryQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetSharedWithMeFeatureSummaryResponse>(
    [GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH],
    () =>
      client
        .get(GET_SHARED_WITH_ME_FEATURE_SUMMARY_PATH)
        .json<GetSharedWithMeFeatureSummaryResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
