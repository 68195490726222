import styled, { css } from 'styled-components';
import {
  Icon,
  UserAvatar,
  UserAvatarProps
} from '@elfsight-universe/ui-common';
import AccountMemberInvitationAvatar from '../assets/account-member-invitation.png';
import AccountMemberInvitationClock from '../assets/account-member-invitation-clock.svg';

export type CollaboratorsUserAvatarProps = {
  profilePictureURL?: string | null;
  isInvitation?: boolean;
  isDeactivated?: boolean;
  placeholderArgs: UserAvatarProps['placeholderArgs'];
  alignTop?: boolean;
  size?: number;
};

export function CollaboratorsUserAvatar({
  profilePictureURL,
  isDeactivated,
  placeholderArgs,
  isInvitation,
  alignTop = false,
  size = 32
}: CollaboratorsUserAvatarProps) {
  return (
    <Container _alignTop={alignTop}>
      <Inner>
        <StyledUserAvatar
          size={size}
          src={
            isInvitation ? AccountMemberInvitationAvatar.src : profilePictureURL
          }
          placeholderArgs={placeholderArgs}
          $withOpacity={isDeactivated}
        />
        {isInvitation && (
          <PendingClock component={AccountMemberInvitationClock} size={20} />
        )}
      </Inner>
    </Container>
  );
}
const Container = styled.div<{ _alignTop?: boolean }>`
  position: relative;
  display: flex;
  align-self: ${({ _alignTop }) => (_alignTop ? 'start' : 'center')};
`;

const Inner = styled.div`
  position: relative;
`;

const StyledUserAvatar = styled(UserAvatar)<{ $withOpacity?: boolean }>`
  ${({ $withOpacity }) =>
    $withOpacity &&
    css`
      opacity: 0.5;
    `}
`;

const PendingClock = styled(Icon)`
  position: absolute;
  bottom: -2px;
  right: -5px;
`;
