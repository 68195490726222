import { useState, startTransition, useEffect } from 'react';
import Image from 'next/image';
import { useRect } from 'react-use-rect';
import styled from 'styled-components';

export interface WidgetThumbnailProps {
  src: string;
  intrinsicWidth: number;
  intrinsicHeight: number;
  quality?: number;
  alt?: string;
}

const THUMBNAIL_SCALE_PADDING_COMPENSATION = 0.97;

export function WidgetThumbnail({
  src,
  intrinsicWidth,
  intrinsicHeight,
  quality = 100,
  alt = ''
}: WidgetThumbnailProps) {
  // we know that thumbnail pixel density is doubled by default,
  // so we have to cut its dimensions in a half to get DIP values
  const maxWidth = Math.floor(intrinsicWidth / 2);
  const maxHeight = Math.floor(intrinsicHeight / 2);

  const imageAspectRation = maxWidth / maxHeight;

  const [effectiveWidth, setEffectiveWidth] = useState<number | undefined>();
  const [effectiveHeight, setEffectiveHeight] = useState<number | undefined>();
  const [imageFitsContainer, setImageFitsContainer] = useState<
    boolean | undefined
  >();
  const [containerMeasureRef, revalidateContainerRect] = useRect(
    (containerRect) => {
      let nextEffectiveWidth: number;
      let nextEffectiveHeight: number;

      const containerAspectRatio = containerRect.width / containerRect.height;
      const landscapeOriented =
        imageAspectRation > 1 || imageAspectRation > containerAspectRatio;
      const imageFitsIntoContainer =
        maxWidth <= containerRect.width && maxHeight <= containerRect.height;
      if (imageFitsIntoContainer) {
        nextEffectiveWidth = maxWidth;
        nextEffectiveHeight = maxHeight;
      } else if (landscapeOriented) {
        nextEffectiveWidth = containerRect.width;
        nextEffectiveHeight = containerRect.width / imageAspectRation;
      } else {
        nextEffectiveHeight = containerRect.height;
        nextEffectiveWidth = containerRect.height * imageAspectRation;
      }

      startTransition(() => {
        setEffectiveWidth(nextEffectiveWidth);
        setEffectiveHeight(nextEffectiveHeight);
        setImageFitsContainer(imageFitsIntoContainer);
      });
    },
    { resize: true }
  );
  useEffect(() => {
    revalidateContainerRect({ force: true });
  }, [src, intrinsicWidth, intrinsicHeight]);

  const dimensionsKnown = !!effectiveWidth && !!effectiveHeight;
  return (
    <Container
      ref={containerMeasureRef}
      style={{
        transform: `scale(${
          imageAspectRation === 1 || imageFitsContainer
            ? 1
            : THUMBNAIL_SCALE_PADDING_COMPENSATION
        })`
      }}
    >
      {dimensionsKnown && (
        <Image
          src={src}
          width={Math.round(effectiveWidth)}
          height={Math.round(effectiveHeight)}
          quality={quality}
          alt={alt}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
