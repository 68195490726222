import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CREATE_PROJECT_INVITATION_PATH,
  CreateProjectInvitationRequest,
  CreateProjectInvitationResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useCreateProjectInvitationMutation(
  options: UseMutationOptions<
    undefined,
    CreateProjectInvitationResponseError,
    CreateProjectInvitationRequest
  > = {}
) {
  return useMutation<
    undefined,
    CreateProjectInvitationResponseError,
    CreateProjectInvitationRequest
  >(
    (data) =>
      client
        .post(CREATE_PROJECT_INVITATION_PATH, {
          json: data
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
