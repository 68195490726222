import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { ViewsLimitTooltip } from './views-limit-tooltip';

export const viewsLimitFeatureResolver: FeatureResolver = (
  { viewsLimit, appsCount },
  { pricingTableColorTheme }
) => ({
  text:
    appsCount && appsCount > 1 ? (
      <ViewsLimitTooltip pricingTableColorTheme={pricingTableColorTheme}>
        <>
          {viewsLimit !== null
            ? `${viewsLimit} views per app`
            : 'Unlimited views'}
        </>
      </ViewsLimitTooltip>
    ) : (
      <ViewsLimitTooltip pricingTableColorTheme={pricingTableColorTheme}>
        <>{viewsLimit !== null ? `${viewsLimit} views` : 'Unlimited views'}</>
      </ViewsLimitTooltip>
    )
});
