import { Exclude, Expose, Type } from 'class-transformer';

export const GET_BADGES_CONTAINING_APP_PATH =
  'widget/get-badges-containing-app';

@Exclude()
export class GetBadgesContainingAppResponse {
  @Expose()
  @Type(() => GetBadgesContainingAppResponseBadge)
  payload: GetBadgesContainingAppResponseBadge[];
}

@Exclude()
export class GetBadgesContainingAppResponseBadge {
  @Expose()
  pid: string;

  @Expose()
  title: string;

  @Expose()
  icon: string;

  @Expose()
  alias: string;
}
