import { ReactNode } from 'react';
import styled from 'styled-components';
import { UrlObject } from 'url';
import { Icon, Link, Typography } from '@elfsight-universe/ui-common';
import ArrowRightIcon from '@icons/arrow-right.svg';
import PresentIcon from '@icons/present.svg';
import { SuccessModalCountdown } from './success-modal-countdown';

interface DealButtonProps {
  href: string | UrlObject;
  text: ReactNode;
  expiredAt?: Date;
}

export function DealButton({
  href,
  text,
  expiredAt,
  ...forwardingProps
}: DealButtonProps) {
  return (
    <Container
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...forwardingProps}
    >
      <StyledPresentIcon component={PresentIcon} size={24} />

      <InnerWrapper>
        <StyledButtonText
          variant="title4Semibold"
          style={{ marginRight: expiredAt ? 8 : 0 }}
        >
          {text}
        </StyledButtonText>

        {expiredAt && <SuccessModalCountdown expiredAt={expiredAt} />}
      </InnerWrapper>

      <StyledArrowIconWrapper>
        <Icon component={ArrowRightIcon} size={18} fill="alert" />
      </StyledArrowIconWrapper>
    </Container>
  );
}

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledButtonText = styled(Typography)`
  margin-right: 8px;
`;

const StyledPresentIcon = styled(Icon)`
  margin-right: 16px;
`;

const StyledArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 20px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 83, 66, 0.1);
  border-radius: 50%;
`;

const Container = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  border-radius: 58px;

  background: linear-gradient(
    94.43deg,
    rgba(255, 83, 66, 0.1) 21.46%,
    rgba(255, 83, 66, 0.06) 83.46%
  );

  &:hover {
    text-decoration: none;

    background: linear-gradient(
      94.43deg,
      rgba(255, 83, 66, 0.2) 21.46%,
      rgba(255, 83, 66, 0.12) 83.46%
    );

    ${StyledArrowIconWrapper} {
      background-color: rgba(255, 83, 66, 0.2);
    }
  }

  &:active {
    background: linear-gradient(
      94.43deg,
      rgba(255, 83, 66, 0.3) 21.46%,
      rgba(255, 83, 66, 0.18) 83.46%
    );

    ${StyledArrowIconWrapper} {
      background-color: rgba(255, 83, 66, 0.3);
    }
  }
`;
