import { Exclude, Expose } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const GET_SHARED_WIDGET_ADDITIONAL_INFO_PATH =
  'widget/get-shared-widget-additional-info';

export class GetSharedWidgetAdditionalInfoRequest {
  @IsUUID()
  widgetPid: string;
}

@Exclude()
export class GetSharedWidgetAdditionalInfoResponse {
  @Expose()
  hasExceededViewsUsage: boolean;
}

export type GetSharedWidgetAdditionalInfoResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
