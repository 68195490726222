import { HTMLAttributes } from 'react';
import NextImage from 'next/image';
import styled from 'styled-components';

type ImageProps = HTMLAttributes<HTMLDivElement> & {
  alt?: string;
  bgImageSrc: string;
  textImageSrc: string;
  textImageMaxWidth?: number;
};

export function Image({
  alt,
  bgImageSrc,
  textImageSrc,
  textImageMaxWidth = 210,
  ...forwardingProps
}: ImageProps) {
  return (
    <Container {...forwardingProps}>
      <BackgroundImageContainer>
        <NextImage
          alt={alt ?? ''}
          src={bgImageSrc}
          style={{
            objectFit: 'cover'
          }}
          fill
        />
      </BackgroundImageContainer>
      <TextImageContainer _maxWidth={textImageMaxWidth}>
        <NextImage
          alt={alt ?? ''}
          src={textImageSrc}
          style={{
            objectFit: 'contain'
          }}
          fill
        />
      </TextImageContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TextImageContainer = styled.div<{ _maxWidth: number }>`
  max-width: ${({ _maxWidth }) => _maxWidth}px;
  position: relative;
  width: 100%;
  height: 100%;
`;
