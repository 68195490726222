import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  RESEND_ACCOUNT_MEMBER_INVITATION_PATH,
  ResendAccountMemberInvitationRequest,
  ResendAccountMemberInvitationResponseError
} from '@elfsight-universe/service-core-contracts/iam';
import { client } from '../client';

export function useResendAccountMemberInvitationMutation(
  options: Pick<
    UseMutationOptions<
      Response,
      ResendAccountMemberInvitationResponseError,
      ResendAccountMemberInvitationRequest
    >,
    'onError' | 'onSuccess'
  > = {}
) {
  return useMutation<
    Response,
    ResendAccountMemberInvitationResponseError,
    ResendAccountMemberInvitationRequest
  >(
    (data) =>
      client.post(RESEND_ACCOUNT_MEMBER_INVITATION_PATH, {
        json: data
      }),
    {
      ...options,
      retry: false
    }
  );
}
